.ant-layout-header { 
  background: #54217c;
  height: 100px;
}

.ant-layout-footer { 
    background: #54217c;
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
}

.ant-btn-primary { 
    background-color: #b0d137;
    border-color: #b0d137;
}

.ant-btn-primary:hover {
    background-color: greenyellow;
    border-color: greenyellow;
}

.btnGroup {
  padding: 5% 5% 5% 5%
}

.ant-btn {
    width: 100%;
    height: 400px;
    font-size: 350%;
}