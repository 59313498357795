
.pl {
    height: 800px;
  }

.bk {
  background: #54217c;
}

@media (max-width: 1200px)  {
  .pl {
    height: 800px 
  }
}


@media (max-width: 768px)  {
    .pl {
      height: 640px 
    }
  }


@media (max-width: 480px)  {
    .pl {
      height:663px 
    }
  }